@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';
@use '../../variable/shadow';
@use '../../variable/transition';
@use '../../mixin/container';

// Old styles from the product page, which will be recreated when building a new product page
// THIS FILE NEEDS TO BE WIPED ENTIRELY

/* stylelint-disable no-duplicate-selectors, declaration-block-no-duplicate-properties  */
.product-information {
  display: flex;
  flex-direction: column;
  margin-left: calc(#{spacing.$s2} * -1);
  margin-right: calc(#{spacing.$s2} * -1);

  @media screen and (min-width: breakpoint.$lg) {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
  }

  &__gallery {
    @media screen and (min-width: breakpoint.$lg) {
      width: calc(100% / 3) * 2;
      padding-right: spacing.$s8;
    }

    &.design-test {
      .design-selection {
        padding: 0 spacing.$s4;

        @media screen and (min-width: breakpoint.$lg) {
          padding: 0;
        }
      }

      .attributeTitle {
        font-weight: font.$weight-600;
        margin-bottom: spacing.$s2;
        margin-top: spacing.$s6;

        @media screen and (min-width: breakpoint.$lg) {
          margin-top: spacing.$s8;
        }
      }

      .attributeText {
        color: var(--theme-font-color-light);
        font-size: font.$size-xxs;
      }
    }
  }

  &__content {
    margin-top: spacing.$s10;

    &.design-test {
      margin-top: 0;
    }

    @media screen and (min-width: breakpoint.$lg) {
      width: calc(100% / 3);
      margin-top: 0;
    }
  }

  &__sticky-cta {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: spacing.$s4;
    background-color: var(--theme-color-background);
    border-top: 1px solid var(--theme-color-base);

    .button__stack {
      margin-top: 0;

      .button {
        margin-right: 56px;
      }
    }

    // Hide on tablet & desktop
    @media screen and (min-width: breakpoint.$lg) {
      display: none;
    }
  }

  // So we have a desktop and mobile class here because they need to behave differently
  // It creates a bigger box on mobile then on desktop.
  &-desktop {
    padding: 0 spacing.$s4;

    @media screen and (min-width: breakpoint.$lg) {
      &.design-test {
        display: none;
      }
    }

    .design-test {
      display: none;

      @media screen and (min-width: breakpoint.$lg) {
        display: block;
      }
    }

    @media screen and (min-width: breakpoint.$lg) {
      border: 1px solid var(--theme-color-base);
      padding: spacing.$s8;
      border-radius: var(--theme-border-radius-sm);
    }
  }

  &-pricing {
    display: flex;
    margin-top: spacing.$s8;
    position: relative;

    .business-order {
      text-align: right;
      position: absolute;
      top: 0;
      right: 0;

      a {
        font-size: font.$size-xxs;
        text-decoration: underline;
      }
    }
  }
}

.product-rating {
  display: flex;
  align-items: center;
  margin-top: spacing.$s2;

  @media screen and (min-width: breakpoint.$lg) {
    margin-top: spacing.$s4;
  }

  span {
    color: var(--theme-font-color);
    font-size: font.$size-xxs;
    margin-left: spacing.$s2;
    display: inline-block;
    padding-top: 3px;
    transition: transition.$default color;

    &:hover {
      color: var(--theme-font-color-light);
    }
  }

  i {
    color: color.$yellow;
    margin-right: 1px;
  }

  a {
    span {
      text-decoration: underline;
    }
  }
}

.shopping-awards {
  margin-top: spacing.$s8;
  padding: 0 spacing.$s4;
  display: none;

  @media screen and (min-width: breakpoint.$lg) {
    padding: 0;
  }

  img {
    border-radius: var(--theme-border-radius-sm);
  }
}

.productPageImageGallery {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: calc(#{spacing.$s8} + 131px);

  &__main {
    position: relative;
    display: flex;
    min-width: 0;
    flex: 1;
  }

  &__container {
    position: relative;
  }

  .design-test & {
    height: 300px;
    position: relative;
    top: 0;
    margin-left: spacing.$s4;
    margin-right: spacing.$s4;
  }

  @media screen and (min-width: breakpoint.$sm) {
    .design-test & {
      height: 400px;
    }
  }

  @media screen and (min-width: breakpoint.$md) {
    .design-test & {
      height: 500px;
    }
  }

  @media screen and (min-width: breakpoint.$lg) {
    flex-direction: row;

    .design-test & {
      margin-left: 0;
      margin-right: 0;
    }
  }

  // Mobile Landscape
  @media screen and (max-width: 1024px) and (max-height: 767px) and (orientation: landscape) {
    flex-direction: column;
  }
}

.product-specifications {
  br {
    content: '';
    margin-bottom: spacing.$s1;
    display: block;
  }
}

#articleSpecifications {
  b {
    font-weight: 600;
  }
}

.gallerySliderContainer {
  width: 100%;
  height: 100%;
  margin: 0;
  background: var(--theme-color-white);

  .design-test & {
    border-radius: var(--theme-border-radius-sm);
  }

  @media screen and (min-width: breakpoint.$lg) {
    margin: 0 spacing.$s6;
    border-radius: var(--theme-border-radius-sm);
    box-shadow: shadow.$drop-bottom;

    .design-test & {
      box-shadow: none;
    }
  }

  // Mobile Landscape
  @media screen and (max-width: 1024px) and (max-height: 767px) and (orientation: landscape) {
    margin: 0;
    padding: 0 spacing.$s6;
  }

  .swiperControls {
    display: none;
  }

  // Weird iOS14 bug where height: auto; stretches the image
  .swiper-slide {
    height: 100%;
    max-width: 100%;
    display: flex;

    .design-test & {
      object-fit: cover;
      justify-content: center;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    img {
      align-self: center;
    }
  }
}

.productPageImageGalleryThumbs {
  margin-top: spacing.$s4;

  @media screen and (min-width: breakpoint.$lg) {
    margin-top: 0;
  }
}

.gallerySliderThumbsContainer.swiper {
  height: auto;
  padding: 0 56px;

  .design-test & {
    margin-top: spacing.$s4;
    padding: 0;
    margin-left: calc(#{spacing.$s4} * -1);
    margin-right: calc(#{spacing.$s4} * -1);

    @media screen and (min-width: breakpoint.$lg) {
      margin-left: 0;
      margin-right: 0;
      height: auto;
      padding: 0 48px;
      margin-top: spacing.$s6;
      border-radius: var(--theme-border-radius-sm);
    }
  }

  @media screen and (min-width: breakpoint.$lg) {
    height: 452px;
    padding: spacing.$s8 0;
  }

  // Mobile Landscape
  @media screen and (max-width: 1024px) and (max-height: 767px) and (orientation: landscape) {
    height: auto;
    padding: 0 spacing.$s8;
  }

  .swiper-wrapper {
    box-sizing: initial;

    @media screen and (min-width: breakpoint.$lg) {
      flex-direction: column;

      .design-test & {
        flex-direction: row;
      }
    }

    // Mobile Landscape
    @media screen and (max-width: 1024px) and (max-height: 767px) and (orientation: landscape) {
      flex-direction: row;
    }
  }

  .swiper-slide {
    height: 56px;
    width: 56px;
    margin: 0 spacing.$s2 0 0;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.1s ease-in padding;
    display: flex;
    border-radius: var(--theme-border-radius-xs);
    overflow: hidden;

    img {
      height: 100%;
    }

    .design-test & {
      width: 124px;
      height: 100%;
      margin: 0;
      border-radius: var(--theme-border-radius-sm);
      border: 1px solid transparent;

      img {
        transition: transform transition.$default;
      }

      @media screen and (min-width: breakpoint.$lg) {
        width: 140px;
        height: 140px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @media screen and (min-width: breakpoint.$lg) {
      margin: 0 0 spacing.$s2 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .slideActive {
    padding: calc(#{spacing.$s2} * 0.5);
    border: 1px solid var(--theme-color-base);

    .design-test & {
      border-color: var(--theme-color-secondary);
      padding: 0;
      overflow: hidden;

      img {
        height: 100%;
        object-fit: cover;
        transform: scale(1.1);
      }
    }
  }

  &--designs {
    .swiper-wrapper {
      flex-direction: initial;
    }
  }
}

.productPageImageGalleryThumbs {
  order: 1;

  @media screen and (min-width: breakpoint.$lg) {
    padding-right: spacing.$s6;
    order: 0;
  }

  // Mobile Landscape
  @media screen and (max-width: 1024px) and (max-height: 767px) and (orientation: landscape) {
    padding: 0;
    order: 1;
  }
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.swiper-pagination-bullet-active {
  background-color: var(--theme-color-secondary);
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}

.swiperVerticalPrev,
.swiperVerticalNext,
.swiperHorizontalPrev,
.swiperHorizontalNext {
  background-color: var(--theme-color-background);
  width: 100%;
  height: 32px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  border: none;
  position: absolute;
  z-index: 2;

  i {
    position: relative;
    font-size: font.$size-icon;
  }
}

.swiperVerticalPrev {
  top: 0;
}

.swiperVerticalNext {
  bottom: 0;
}

.swiperHorizontalPrev,
.swiperHorizontalNext {
  width: 48px;
  height: 100%;
  top: 50%;
  transform: translate(0, -50%);

  .design-test & {
    display: none;
    border: 1px solid var(--theme-color-base);
    border-radius: var(--theme-border-radius-sm);

    @media screen and (min-width: breakpoint.$lg) {
      transition: border transition.$default;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        border: 1px solid var(--theme-color-base-semi-dark);
      }
    }
  }
}

.swiperHorizontalPrev {
  left: 0;

  i {
    .design-test & {
      left: -1px;
    }
  }
}

.swiperHorizontalNext {
  right: 0;

  i {
    .design-test & {
      right: -1px;
    }
  }
}

.swiperVerticalPrev.swiper-button-disabled,
.swiperVerticalNext.swiper-button-disabled,
.swiperHorizontalPrev.swiper-button-disabled,
.swiperHorizontalNext.swiper-button-disabled {
  opacity: 0.6;
  transition: opacity transition.$default;
  background-color: var(--theme-color-background);
}

// Make sure non active slides are always hidden
.swiper-container-fade .swiper-slide {
  /* stylelint-disable-next-line */
  opacity: 0 !important;
}

// Make sure active slide is always visible
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  /* stylelint-disable-next-line */
  opacity: 1 !important;
}

.gallerySliderThumbsContainer.noNavigation {
  padding: 0;

  .swiperVerticalPrev,
  .swiperVerticalNext {
    display: none;
  }
}

.button360 {
  display: none;

  @media screen and (min-width: breakpoint.$lg) {
    bottom: 10px;
    cursor: pointer;
    font-size: 18px;
    left: 10px;
    padding: 15px 20px;
    position: absolute;
    border-radius: 50%;
    z-index: 2;
  }
}

.issuuembed {
  width: auto;
  height: 400px;

  @media screen and (min-width: breakpoint.$lg) {
    width: 730px;
    height: 600px;
  }
}

.priceDetails {
  > div:first-child:not(.article-price) {
    color: var(--theme-font-color-lighter);
  }

  div + .article-price {
    margin-top: spacing.$s2;
  }
}

.article-price {
  &__total {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 100%;
    margin-bottom: spacing.$s2;
  }

  &__shipping {
    font-size: font.$size-xxs;
    color: var(--theme-font-color-light);
  }
}

// If the customer uses a voucher code we replace the price on the page for a checkmark
// to show its already being payed for.
.priceDetailsWrapper {
  .already_payed {
    font-size: 24px;
    padding-left: 5px;
    vertical-align: middle;
    color: var(--theme-color-primary);
  }
}

#deliveryInformation {
  .shippingCosts {
    text-align: center;
    margin-bottom: 0;
  }
}

.productUsps {
  display: flex;
  align-items: center;
  padding: 0 spacing.$s4;

  @media screen and (min-width: breakpoint.$lg) {
    padding: 0;
  }

  .businessQuotation & {
    padding: 0;
  }

  &__wrapper {
    margin-top: spacing.$s8;
    margin-bottom: spacing.$s10;

    @media screen and (min-width: breakpoint.$lg) {
      margin-top: spacing.$s10;
      margin-bottom: 0;
    }

    & > * {
      margin-top: calc(#{spacing.$s6} * 0.5);

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}

/* USPS */
.uspIcons {
  margin-right: spacing.$s4;
  color: var(--theme-color-primary);
}

/*
COMBINED ARTICLE
This is the selection in the product information when there are different colors, sizes etc.
*/
.combinedArticle__button {
  border: none;
  background: none;
  font-size: 22px;
  padding: 0;
  margin-right: spacing.$s6;

  &--selected {
    color: var(--theme-font-color);
  }
}

.combinedArticleAttribute option:disabled {
  color: var(--theme-font-color-lighter);
  -webkit-text-fill-color: var(--theme-font-color-lighter);
}

.combinedArticleAttribute.error {
  background-color: var(--theme-color-error);
}

.combinedArticleAttributeColor {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  cursor: pointer;
  margin-right: spacing.$s2;
  margin-bottom: spacing.$s2;
  border: 2px solid var(--theme-color-white);
  background-clip: padding-box;

  &.selected {
    box-shadow: shadow.$drop-dark-bottom, 0 0 0 1px color.$base-60;
  }
}

.combinedArticleAttributeImage {
  cursor: pointer;
  margin-bottom: spacing.$s6;
  text-align: center;
  border: 1px solid transparent;
  transition: transition.$default all;
  padding: 0 spacing.$s6 spacing.$s6;
  position: relative;
  max-width: 150px;

  @media screen and (min-width: breakpoint.$lg) {
    max-width: 50%;
  }

  &.selected {
    color: var(--theme-font-color);
    border: 1px solid var(--theme-color-base);
  }

  .ourChoice {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(#{spacing.$s6} * -1);
    right: calc(#{spacing.$s2} * -1);
    width: 56px;
    height: 56px;
    background-color: var(--theme-color-secondary);
    color: var(--theme-color-white);
    border-radius: 100%;
    font-size: font.$size-xxs;
    font-weight: font.$weight-bold;
  }

  .valueName {
    margin-top: spacing.$s2;
  }

  .valuePrice {
    color: var(--theme-font-color-light);
    font-size: font.$size-xxs;
    margin-top: calc(#{spacing.$s2} * 0.5);
  }
}

.combinedArticleError {
  color: var(--theme-color-error);
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: -10px;
  text-align: center;

  i {
    font-size: 1.65em;
  }
}

.combinedArticleAttributeRow {
  margin-top: spacing.$s6;

  .attributeTitle {
    margin-bottom: spacing.$s4;
    font-weight: 600;
  }

  .attributeValues {
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
  }

  .attributeSelect {
    position: relative;
    width: 100%;

    &:after {
      top: 50%;
      right: spacing.$s6;
      transform: translate(0, -50%);
    }
  }

  select {
    width: 100%;
    font-family: font.$primary;
    padding: 20px spacing.$s6;
    border: 0;
    border-radius: var(--theme-border-radius-sm);
    appearance: none;
    background-color: color.$bg-grey;
    cursor: pointer;
    color: var(--theme-font-color);
    font-size: font.$size-base;
  }
}

.attributeSelect:after {
  color: var(--theme-font-color);
  content: '\f078';
  font-family: font.$icon;
  height: 15px;
  pointer-events: none;
  position: absolute;
  width: 20px;
}

.radioBar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid transparent;

  input {
    display: none;
  }

  &__button {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    padding: spacing.$s4;
    transition: background 0.3s, color 0.3s;
    border-radius: initial;
    border: 1px solid var(--theme-color-base);
    border-left: 1px solid transparent;
    text-align: center;
    cursor: pointer;

    @media screen and (min-width: breakpoint.$lg) {
      padding: spacing.$s6 spacing.$s4;
    }

    &__badge {
      border: 1px solid transparent;
      position: absolute;
      top: -13px;
      transition: background 0.3s, color 0.3s;
      background: linear-gradient(#FCF8F4, #FCF8F4) padding-box,
        linear-gradient(#EAE6E2, #EAE6E2) top left/50% 13px border-box,
        linear-gradient(#EAE6E2, #EAE6E2) top right/50% 13px border-box;
      background-repeat: no-repeat;
    }

    &__price {
      font-size: 11px;
      color: var(--theme-font-color);
      margin-left: 0;
      margin-top: calc(#{spacing.$s2} * 0.5);
    }

    &:hover:not(:checked) {
      background-color: #F5F2EE;

      .radioBar__button__badge {
        background: linear-gradient(#F5F2EE, #F5F2EE) padding-box,
          linear-gradient(#EAE6E2, #EAE6E2) top left/50% 13px border-box,
          linear-gradient(#EAE6E2, #EAE6E2) top right/50% 13px border-box;
        background-repeat: no-repeat;
      }
    }

    &:first-of-type {
      border-left: 1px solid var(--theme-color-base);
      border-radius: var(--theme-border-radius-sm) 0 0 var(--theme-border-radius-sm);
    }

    &:last-of-type {
      border-radius: 0 var(--theme-border-radius-sm) var(--theme-border-radius-sm) 0;
    }
  }

  & input:checked + &__button {
    border: 1px solid color.$base;
    color: var(--theme-font-color);
    cursor: default;

    .radioBar__button__badge {
      background: linear-gradient(#FCF8F4, #FCF8F4) padding-box,
        linear-gradient(color.$base, color.$base) top left/50% 13px border-box,
        linear-gradient(color.$base, color.$base) top right/50% 13px border-box;
      background-repeat: no-repeat;
    }
  }

  & input:disabled + &__button {
    cursor: default;
    color: var(--theme-font-color-lighter);
    text-decoration: line-through;

    &:hover {
      background: none;
    }
  }
}

.product-video-container > div {
  border-radius: var(--theme-border-radius-sm);
  overflow: hidden;
  box-shadow: shadow.$drop-bottom;
}

.specifications-container {
  margin-top: spacing.$s10;
  width: 100%;

  @media screen and (min-width: breakpoint.$lg) {
    margin-top: spacing.$s18;
  }
}

.specifications-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: spacing.$s6;
  text-align: left;
  border: 1px solid var(--theme-color-base);
  transition: transition.$default all;
  border-radius: var(--theme-border-radius-sm);
  background-color: transparent;
  margin-top: spacing.$s2;
  font-size: font.$size-base;

  &:first-child {
    margin-top: 0;
  }

  &--disabled {
    opacity: 0.6;
    cursor: default;
  }

  @media screen and (min-width: breakpoint.$lg) {
    margin-top: 0;

    &:hover {
      border-color: transparent;
      background-color: color.$bg-grey;

      i {
        --fa-primary-color: var(--theme-color-secondary);
        --fa-secondary-color: var(--theme-color-secondary);
      }
    }

    &--disabled {
      &:hover {
        background-color: color.$bg-grey;
      }
    }
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: spacing.$s4;

    span:first-child {
      font-weight: font.$weight-600;
      margin-bottom: 4px;
    }

    span:last-child {
      font-size: font.$size-xxs;
    }
  }

  i {
    &.fa-gift {
      --fa-primary-color: rgb(222, 137, 110);
      --fa-secondary-color: rgb(222, 137, 110);
    }

    &.fa-stars {
      --fa-primary-color: rgb(222, 172, 110);
      --fa-secondary-color: rgb(222, 172, 110);
    }

    &.fa-file-signature {
      --fa-primary-color: rgb(85, 76, 68);
      --fa-secondary-color: rgb(85, 76, 68);
    }

    &.fa-circle-info {
      --fa-primary-color: rgb(58, 121, 145);
      --fa-secondary-color: rgb(58, 121, 145);
    }

    &:first-of-type {
      align-self: center;
      font-size: 20px;
    }

    &:last-of-type {
      margin-left: auto;
    }
  }
}

.specifications-title {
  margin-bottom: spacing.$s4;

  @media screen and (min-width: breakpoint.$lg) {
    margin-bottom: spacing.$s2;
  }
}

.specifications-text {
  color: var(--theme-font-color-light);
}

.shipping-methods {
  margin-top: spacing.$s8;
  border-top: 1px solid var(--theme-color-base);
  border-bottom: 1px solid var(--theme-color-base);

  @media screen and (min-width: breakpoint.$lg) {
    border: 1px solid var(--theme-color-base);
    border-radius: var(--theme-border-radius-sm);
  }

  &__title {
    font-size: font.$size-xs;
    margin-bottom: spacing.$s2;
    padding: spacing.$s4 spacing.$s4 0 spacing.$s4;
    font-family: font.$secondary;

    @media screen and (min-width: breakpoint.$lg) {
      padding: spacing.$s8 spacing.$s8 0 spacing.$s8;

    }
  }

  .shipping-method {
    padding-bottom: spacing.$s4;

    .shipping-container:last-child {
      .shipping-information {
        border-bottom: 0;
      }
    }
  }
}

.shipping-content {
  margin-top: spacing.$s8;
}

.show-more-less-separator {
  // Add box-shadow when custom properties aren't available because the static height isn't always
  // correct without custom properties, this makes it more obvious for the user to click on 'show more'.
  @supports (--custom-property: 0) {
    &--shipping {
      box-shadow: none;
    }
  }
}

.shipping-information-container {
  padding-top: spacing.$s4;
}

.shipping-information {
  display: flex;
  font-size: font.$size-xxs;
  padding: spacing.$s4;
  border-bottom: 1px solid var(--theme-color-base);
  flex-direction: column;

  @media screen and (min-width: breakpoint.$lg) {
    padding: spacing.$s4 spacing.$s8;
  }

  &__method {
    display: flex;
    flex-direction: row;

    &__price {
      flex-shrink: 0;
      margin-left: auto;
      text-align: center;
    }

    &__title {
      color: var(--theme-font-color-light);
      margin-top: 4px;
    }

    &__text {
      margin-right: spacing.$s2;
    }
  }
}

.semi-hidden {
  visibility: hidden;
  opacity: 0;
}

.info-popup-link {
  margin: spacing.$s6 0 spacing.$s10;

  a {
    display: inline-flex;
    align-items: center;
  }

  i {
    color: color.$sea;
    font-size: font.$size-icon;
    margin-right: spacing.$s2;
    top: -1px;
    position: relative;
  }
}

.article-faq .p {
  margin-top: 0;

  h3,
  h5 {
    margin-bottom: spacing.$s6;
  }

  i {
    font-weight: font.$weight-bold;
    font-style: normal;
  }
}

.businessQuotation {
  padding: 0 spacing.$s2;

  @media screen and (min-width: breakpoint.$md) {
    padding: 0;
  }
}
