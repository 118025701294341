@use '../../variable/color';
@use '../../variable/font';
@use '../../variable/transition';

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  text-align: left;
  background-color: transparent;
  padding: 0;
  color: var(--theme-font-color);
  font-size: font.$size-base;

  &:hover i {
    transform: rotate(180deg);
  }

  i {
    font-size: font.$size-icon;
    transition: transition.$default transform;
  }
}
