// Variable
@use '../../variable/breakpoint';
@use '../../../stories/scss/settings/spacing';

.slider {
  &__wrapper {
    .design-test & {
      margin: spacing.$s4 calc(#{spacing.$s4} * -1) 0 calc(#{spacing.$s4} * -1);
      padding: 0;
      scroll-padding-left: spacing.$s4;

      @media screen and (min-width: breakpoint.$md) {
        margin: spacing.$s4 0 0;
      }

      &--is-scrollable {
        cursor: pointer;
      }
    }

    &:before,
    &:after {
      content: '';
      height: 156px;
      width: 16px;
      flex: 0 0 spacing.$s4;
      scroll-snap-align: start;

      @media screen and (min-width: breakpoint.$md) {
        display: none;
      }
    }

    &__slide {
      @media screen and (min-width: breakpoint.$md) {
        .design-test & {
          margin-right: spacing.$s4;
        }
      }
    }
  }

  &__button {
    @media screen and (min-width: breakpoint.$md) {
      &--hidden {
        .design-test & {
          display: flex;
          opacity: 0.4;
          pointer-events: all;
        }
      }
    }

    &__prev {
      &--horizontal {
        @media screen and (min-width: breakpoint.$md) {
          /* stylelint-disable-next-line declaration-no-important */
          left: spacing.$s4 !important;
        }
      }
    }

    &__next {
      &--horizontal {
        @media screen and (min-width: breakpoint.$md) {
          /* stylelint-disable-next-line declaration-no-important */
          right: spacing.$s4 !important;
        }
      }
    }
  }
}
