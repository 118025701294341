@use '../../variable/breakpoint';
@use '../../variable/color';
@use '../../variable/font';
@use '../../../stories/scss/settings/spacing';

.faq-answer {
  background-color: color.$bg-grey;
  border-radius: 0;

  @media screen and (min-width: breakpoint.$md) {
    border-radius: var(--theme-border-radius-sm);
  }

  &.util--shown {
    padding: spacing.$s8 spacing.$s6;
    margin: spacing.$s6 0 spacing.$s10;

    @media screen and (min-width: breakpoint.$md) {
      padding: spacing.$s8;
      margin: spacing.$s6 0 spacing.$s8;
    }
  }
}
